import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { ADMIN_SHOP_PAGES } from 'Consts/routes';

import { withVariables } from 'Utils/string';

import StyledComponent from 'Components/core/StyledComponent';
import PageContent from 'Components/layout/panel/PageContent';
import TabNavigation from 'Components/layout/panel/TabNavigation';
import Editor from 'Components/admin/shopPages/Editor';

export const TAB_DATA = 'data';

export default class AdminUserShopPageCreate extends Component {
    static propTypes = {
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
    };

    render() {
        const { location, history } = this.props;

        return (
            <StyledComponent
                className="admin-shop-page-create"
                styles={require('./styles')}
            >
                <PageContent
                    breadcrumbs={[{
                        to: withVariables(ADMIN_SHOP_PAGES.path),
                        label: 'Lista sklepów',
                    }]}
                >
                    <TabNavigation
                        location={location}
                        history={history}
                        headline="Utwórz sklep"
                        tabs={[{
                            key: TAB_DATA,
                            label: 'Dane',
                            children: (
                                <Editor
                                    location={location}
                                    history={history}
                                />
                            ),
                        }]}
                    />
                </PageContent>
            </StyledComponent>
        );
    }
}